
// Slideshow

// 1rem = 16px;
// vh = viewport-height (Browser-Fensterhöhe)
// 100vh = 100% der Browser-Fensterhöhe
// 50vh = 50% Browser-Fensterhöhe

.template--slideshow {
  margin-bottom: 1rem;
  background: $farbe1;  
}


// Browser Fenster kleiner als 50rem (800px)
// max = maximalbreite, min = minimalbreite

@include width(max, 50) {

  .slideshow__slide__image {
    height: 0;
    padding-bottom: 100%;
    max-height: 50vh;
  }

  .slideshow__slide__text {
    width: 100%;
    padding: 1rem 0;
    color: #fff;
    background: $farbe1;
    text-align: center;    
  }

  .flickity-page-dots {
    position: static;
    margin: 1rem 0;
  }

}


// Browser Fenster größer als 50rem (800px)

@include width(min, 50) {

  .template--slideshow {
    height: 50rem;
  }

  .slideshow__slide {
    height: 100%;
  }

  .slideshow__slide__image {
    width: 100%;
    // height: 0;
    // padding-bottom: 50%;
    height: 30rem;
    background-size: cover;
    background-position: center;
  }

  .slideshow__slide__text {
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 2rem 0;
  }

  .flickity-page-dots {
    bottom: 1rem;
  }

}





// Slideshow Arrows

.slideshow__prev,
.slideshow__next {
  top: 50%;
  height: 4rem;
  width: 1.25rem;
  margin-top: -2rem;
}

.slideshow__prev {
  left: .5rem;
}

.slideshow__next {
  right: .5rem;
}

.slideshow__prev,
.slideshow__next {
  &:active svg path {
    stroke: $farbe1;
  }
}

.no-touchevents {
  .slideshow__prev,
  .slideshow__next {
    &:hover svg path {
      stroke: $farbe1;
    }
  }
}


// Slideshow Typografie

.slideshow__slide__text  {

  h1 {
    margin-bottom: .75rem;
  }

}

// Dots

.flickity-page-dots {
  bottom: 1rem;
}







// Headerimage

.template--headerimage {
  position: relative;
  width: 100%;
  height: 40rem;
}

.content-parallax {
  margin-top: 20rem;
  // background: #fff;
  padding-top: 0rem;
}


.headerimage__text {
  position: absolute;
  left: 0;
}


@include width(max, 50) {

  .headerimage__image {
    height: 100%;
  }

  .headerimage__text {
    top: 100%;
    height: 0%;
    width: 100%;
    padding: 1.5rem 0;
    color: #fff;
    background: $farbe1;
    text-align: center;    
  }

}


@include width(min, 50) {

  .headerimage__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }


  .headerimage__text {
    position: absolute;
    bottom: 10%;
    left: 1rem;
  }

}


.headerimage__text  {

  h1 {
    margin-bottom: .75rem;
  }

}

