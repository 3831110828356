
.footerinformationen.template--textbild {
	padding-top: 2rem;
	background: $grau1;
}


.fussleiste {
	color: #fff;
	padding: 0;
	background: $farbe1;

	.textbild__elements-container {
		padding: 0;	
	}

	.textbild__element {
		padding-top: 1rem;
		padding-bottom: .5rem;
	}

	.textbild__element__text {
		margin: 0;
	}


	a {
		color: #fff;
		position: relative;
	}
	
	a:after {
		content: '';
		position: absolute;
		top: 110%;
		width: 100%;
		left: 0;
		height: 1px;
		background: none;
	}

}

.no-touchevents .fussleiste a:hover,
.fussleiste a:active {

	color: #fff!important;

	&:after {
		background: #fff;
	}
}