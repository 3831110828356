
// Logo

.logo {
  left: 0;
  top: 0;
  width: 10rem;
  margin-top: .5rem;
  @include margin-left(1);
}

// On Mouse-Hover and -Click (class always without period!)
@include events(logo) {
  opacity: .6;
  transition: opacity .3s;
}


@media only screen and (min-width: $navumschaltbreite) {

  .logo {
    width: 15rem;
  }

  .nav-container--not-hidden {
    &.nav-container--sticky {
      .logo {
        width: 5rem;
      }
    }
  }
}



// Breite der Dropdown-Navigationsfelder 

@media only screen and (min-width: $navumschaltbreite) {
  .nav-container--not-hidden {
    .subnav {
      width: 200%;
      min-width: 20rem;
    }
  }
}




// Höhe des Kopfes der Seite:

// 1. Abstand des Contents zum Kopf der Seite

.maincontent {
  margin-top: 8.5rem;
}
.nav-container {
  height: 8.5rem;
}


@include width(min, 50) {
  
  .maincontent {
    margin-top: 10.5rem;
  }
  .nav-container {
    height: 10.5rem;
  }

}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
// Mobile-Menü 
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media only screen and (max-width: $navumschaltbreite) {

  // Abstand der Navigationspounkte nach oben
  .nav__ul {
    margin-top: 5em;
  }

  .nav-container--sticky {
    .nav__ul {
      margin-top: 5rem;
    }
  }

  // Farben


  .nav,
  .nav ul {
    background: $grau1;
  }

  // Kopfleiste ohne Runterscrollen
  .nav-container {
      // background: yellow;
  }

  // Farbe der Navigationsleiste(=Button) beim Runterscrollen
  .nav-container--sticky {
    .nav__button {
      background: #fff;
    }

    // Farbe des Buttons bei geöffnetem Menü
    &.nav-container--open {
      .nav__button {
        background: $farbe1;
      }
    }

  }

  // Schriftfarbe
  .subnav__a {
    background: blue;
    color: red;
  }

  // On mouse-hover or click/touch
  @include events(subnav__a) {
    color: #fff;
    color: green;
  }

}



// Vertikale Positionierung der Navigationspunkte,
// wenn man am Kopf der Seite ist


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
// Desktop Menü
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media only screen and (min-width: $navumschaltbreite) {

  // Normales Desktopmenü: Navigationscontainer nicht versteckt
  .nav-container--not-hidden {

    // Vertikale Positionierung der Navigationspunkte,
    // wenn man am Kopf der Seite ist
    .nav {
      padding-top: 2.5rem;
    }    

    // Höhe des Navigationskastens, wenn man herunterscrollt
    &.nav-container--sticky {
      height: 5.5rem;
      
      // Vertikale Positionierung der Navigationspunkte
      // wenn man am herunterscrollt      
      .nav {
        padding-top: .75rem;
      }
    }
    
  }



  // Farbe der Kopfleiste

  .nav-container {
    
    &.nav-container--not-sticky {
      // background: #fff;
    }

    &.nav-container--sticky {
      background: #fff;
    }

  }



}


