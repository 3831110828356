// Schriften
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
// @import url(https://fonts.googleapis.com/css?family=Merriweather:400,400italic,700);

$schrift1: 'Roboto Condensed', sans-serif;
$schrift2: 'Roboto Condensed', sans-serif;

// Als Wert: "normal" (ohne Anführungszeichen) oder eine Zahl
$schrift1-regular: 400;
$schrift2-regular: 400;

// Als Wert: "bold" (ohne Anführungszeichen) oder eine Zahl
$schrift1-bold: 700;
$schrift2-bold: 700;


// Farben

$farbe1: #005881;
$farbe2: #efefef;
$grau1: #c6c6c6;
$grau2: #eaeaea;
$grau3: #ccc;


// Maximalbreite der Seite insgesamt
$maxwidth: 200rem;

// Maximalbreite der Header
$maxwidth_header: 140rem;

// Maximalbreite der inneren Seitenelemente
$maxwidth_inner: 80rem;

// Maximalbreite eines Text-Bild-Kastens
$textbox_maxwidth: 60rem;


// Breite, bei der auf Mobile-Navigation umgeschaltet wird
// 1rem = 16px
$navumschaltbreite: 50rem;






// Hintergrundbild /-farbe

body {
	// background-color: #eaeaea;
	// background-image: url('path/to/image');	
}

.maincontent {
	background-color: #fff;
}
// Seitenhintergrund

.footerinformationen {
	background: $grau2;
}

.fussleiste {
	color: #fff;
	padding: 0;
	background: $farbe1;
}
