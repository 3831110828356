
.template--headerimage {
  position: relative;
  width: 100%;
  height: 30rem;

  .touchevents & {
    height: 100vh;
  }
}

// Parallax

.no-touchevents .template--headerimage.headerimage--parallax {
  position: fixed;
  top: 0;
  max-width: $maxwidth_header;
}


.content-parallax {
  margin-top: 25rem;
  position: relative;
  z-index: 100;
  transform: translateZ(0);
  background: #fff;
  padding-top: 2rem;

  .touchevents & {
    margin-top: 0;
  }

}

// Inside the Header

.headerimage__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity .4s;

  &.loaded {
    opacity: 1;
  }
}



.headerimage__text {
  position: absolute;
  left: 0;
  width: 100%;
}

@include width(max, 50) {

  .headerimage__image {
    height: 50%;
  }

  .headerimage__text {
    top: 50%;
    height: 50%;
    padding: 1rem 0;
    color: #fff;
    background: $farbe1;
    text-align: center;    
  }

}


@include width(min, 50) {

  .headerimage__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }


  .headerimage__text {
    position: absolute;
    bottom: 20%;
  }

}


.headerimage__text  {

  h1 {
    margin-bottom: .75rem;
  }

}


