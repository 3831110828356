

// news__layout-1

.news__layout-1 {

	overflow: hidden;
	padding: 1rem 0;

	.news-teaser {
		float: left;
		padding: 1rem 0;
		@include margin-left(1);
	}


}



.news__layout-1 {

  &.news--c2 .news-teaser,
  &.news--c3 .news-teaser,
  &.news--c4 .news-teaser {
    @include cols(22);
  }

}

@include width(min, 40) {


  .news__layout-1 {

    &.news--c2 .news-teaser,
    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser {

      @include cols(10.5);
      
      &:nth-of-type(2n+1) {
        clear: both;
      }

    }
  }
}

@include width(min, 50) {

  .news__layout-1 { 
    
    &.news--c3 .news-teaser,
    &.news--c4 .news-teaser {

      @include cols(6.66666666);

      &:nth-of-type(2n+1) {
        clear: none;
      }

      &:nth-of-type(3n+1) {
        clear: both;
      }

    }
  }
}

@include width(min, 60) {

  .news__layout-1 {

    &.news--c4 .news-teaser {

      @include cols(4.75);

      &:nth-of-type(3n+1) {
        clear: none;
      }

      &:nth-of-type(4n+1) {
        clear: both;
      }

    }
  }
}









