// Seitenhintergrund

.footerinformationen {
	background: #fff;
}

.fussleiste {
	color: #fff;
	padding: 0;
	background: $farbe1;
}

