
@mixin clearfix {
  &:before,
  &:after {
    content: ''; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

@mixin span($cols, $of:24) {
  width: 100 / $of * $cols * 1%;
}

@mixin cols($cols, $of:24) {
  width: 100 / $of * $cols * 1%;
}

@mixin margin-left($cols, $of:24) {
  margin-left: 100 / $of * $cols * 1%;
}

@mixin margin-right($cols, $of:24) {
  margin-right: 100 / $of * $cols * 1%;
}

@mixin left($cols, $of:24) {
  left: 100 / $of * $cols * 1%;
}

@mixin right($cols, $of:24) {
  right: 100 / $of * $cols * 1%;
}


@function colsize($cols, $of:24) {
  @return 100 / $of * $cols * 1%;
}


@mixin width($minmax, $value) {
  @media only screen and (#{$minmax}-width: #{$value}rem) {
    @content;
  }
}


@mixin events($class) {
  .no-touchevents .#{$class}:hover,
  .#{$class}:active {
    @content; 
  }
}



// All Stylesheets
@import 'scss-config/basics';
@import '../../_editor-config/editor-basics';

@import 'scss-dev/reset';
@import 'scss-dev/layout';
@import 'scss-dev/type';
@import 'scss-dev/navigation';
@import 'scss-dev/footer';
@import 'scss-dev/news';
@import 'scss-dev/news-layout-1';
@import 'scss-dev/news-layout-2';
@import 'scss-dev/kontakt';

@import 'scss-dev/flickity';
@import 'scss-dev/slideshow';
@import 'scss-dev/headerimage';
@import 'scss-dev/textbild';
@import 'scss-dev/textbild-layout-1';
@import 'scss-dev/textbild-layout-2';
@import 'scss-dev/textbild-images';
@import 'scss-dev/login';

@import 'scss-config/type';
@import 'scss-config/navigation';
@import 'scss-config/header';
@import 'scss-config/footer';
@import 'scss-config/text-bild-news';
@import 'scss-config/layout';


@import '../../_editor-config/editor-layout';
@import '../../_editor-config/editor-type';
@import '../../_editor-config/editor-navigation';
